@import "~antd/lib/style/themes/default.less";
@import "~antd/lib/style/index-pure.less";
@import "antd/dist/antd.less";
@import "colors";

@primary-color: @blue-6; // primary color for all components
@seconday-color: @gray-6; // primary color for all components
@link-color: @blue-6; // link color
@success-color: @green-5; // success state color
@warning-color: @yellow-5; // warning state color
@error-color: @red-5; // error state color
@font-size-base: 16px; // major text font size
@heading-color: @gray-9; // heading text color
@text-color: @gray-9; // major text color
@text-color-secondary: @gray-6; // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 8px; // major border radius
@border-color-base: @gray-2; // major border colo
@box-shadow-base: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);

@font-family: Roboto Flex, sans-serif;
//Layout
@layout-body-background: @white;
@layout-header-background: #FFFFFF;
@layout-sider-background: @gray-9;

//Sidebar
@menu-item-color: @gray-1;
@menu-bg: transparent;
@menu-item-active-bg: @gray-7;
@menu-highlight-color: @white;
@menu-inline-submenu-bg: @gray-9;
@collapse-header-bg: @primary-color;
@menu-icon-margin-right: 14px;
@menu-item-vertical-margin: 2px;
@menu-item-boundary-margin: 4px;
@menu-icon-size: 20px;

@menu-dark-highlight-color: @gray-1;
@menu-dark-bg: @gray-9;
@menu-dark-inline-submenu-bg: @gray-9;
@menu-dark-item-active-bg: @gray-7;

//Table
@table-header-bg: @gray-50;
@table-header-color: @text-color-secondary;
@table-border-color: @gray-2;
@table-border-radius-base: 12px;
@table-font-size: 14px;

//Page header
@page-header-padding: 0;
@page-header-heading-title: 20px;

//Button
@btn-circle-size: 32px;
@btn-height-base: 40px;
@btn-height-lg: 48px;
@btn-font-weight: 700;

//Card
@card-radius: 12px;
@card-padding-base: 24px;
@card-head-color: @heading-color;
.ant-card {
  box-shadow: @box-shadow-base;
  border: 1px solid @gray-2;
}

//Tabs
@tabs-card-active-color: @blue-7;
.ant-tabs-tab {
  font-weight: 600;
  color: @gray-5;
}

//Body Overrides
@body-background: #fff;

//Tag
@tag-border-radius: 16px;
.ant-tag {
  text-transform: capitalize;
}
.ant-tag-green {
  color: @green-7;
  background: @green-50;
  border-color: transparent;
}

//Input Overrides
@input-height-base: 40px;
@input-height-lg: 48px;
@input-border-color: @gray-3;
@input-placeholder-color: @gray-5;
@input-padding-horizontal-base: 14px;
//@input-padding-vertical-base: 10px;

//Select Overrides
@select-height-base: 40px;

//Timeline
@timeline-dot-bg: @blue-6;
@timeline-color: @gray-2;
@timeline-item-padding-bottom: 10px;

.ant-image-preview-wrap {
  right: 378px;
  z-index: 1000;
}

.ant-image-preview-mask,
.ant-image-preview-operations {
  display: none;
}

.ant-image-mask:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ant-drawer {
  z-index: 999;
}
